export class ExpeditedManufacturingRequest {
    static defaultThresholdDays = 5;

    public id: number;
    public threshold: number;
    public message: string;

    constructor() {
        this.threshold = ExpeditedManufacturingRequest.defaultThresholdDays;
        this.message = getLocalizedDefaultMessage();
    }
}

function getLocalizedDefaultMessage(): string {
    return $localize`Rush orders may be subject to additional fees. Please call our office for more information.`;
}
