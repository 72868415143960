export class Breadcrumb {
    public label: string;
    public state: string;
    public iconName: string;
    public iconNameFilled: string;
    public completedState: string;
    public active: boolean;
    public disabled: boolean;
    public hidden: boolean = false;
}
